import { getCookie, isUUID, setCookie, setUUID } from '@common/helpers/cookie';
import { useQuery } from '@react/services/api';
import {
  REFERRER,
  SESSION,
  UTM_COOKIE,
  UTM_UPDATED,
} from '@sly/core/constants/cookie';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';

const UserCookiesContainer = ({ children }) => {
  const { query: qs } = useRouter();
  const getUUIDAux = useQuery('getUuidAux');

  const setUUIDCookie = useCallback(() => {
    if (isUUID(qs.sly_uuid) && !getCookie(SESSION)) {
      setUUID(qs.sly_uuid);
    }
  }, [qs]);

  const setUTMParamsCookie = useCallback(() => {
    if (qs.utm_campaign) {
      const utmParams = {
        campaign: qs.utm_campaign,
        source: qs.utm_source || 'NA',
        medium: qs.utm_medium || 'NA',
        content: qs.utm_content || 'NA',
        term: qs.utm_term || 'NA',
        timestamp: new Date().toISOString(),
        fbclid: qs.fbclid || 'NA',
        gclid: qs.gclid || 'NA',
      };

      // set cookie with new utm. If utm cookie present append to it
      const utmCookie = getCookie(UTM_COOKIE) || {};
      const clicks = utmCookie.clicks || [];
      clicks.push(utmParams);
      utmCookie.clicks = clicks;
      setCookie(UTM_COOKIE, JSON.stringify(utmCookie));
      // used by api middleware to check and update uuid_aux of user
      setCookie(UTM_UPDATED, true);
      getUUIDAux({ id: 'me' });
    }
  }, [
    getUUIDAux,
    qs.utm_campaign,
    qs.utm_content,
    qs.utm_medium,
    qs.utm_source,
    qs.utm_term,
    qs.fbclid,
    qs.gclid,
  ]);

  const setReferrer = () => {
    const { referrer } = document;
    setCookie(REFERRER, referrer);
    return referrer;
  };

  const setIfEmptyReferrer = () => {
    return getCookie(REFERRER) || setReferrer();
  };

  useEffect(() => {
    setUUIDCookie();
    setUTMParamsCookie();
    setIfEmptyReferrer();
  });

  return children;
};

export default UserCookiesContainer;
