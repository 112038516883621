import merge from 'lodash/merge';

import {
  API_URL,
  ASSETS_URL,
  BUILDER_API_KEY,
  CAUSEWAY_KEY,
  CMS_URL,
  DISABLE_EXPERIMENTS,
  DOMAIN,
  ENABLE_EXPERIMENT_DEBUGGER,
  FB_CLIENT_ID,
  GA_ENV,
  GOOGLE_CLIENT_ID,
  GOOGLE_MAPS_API_KEY,
  HIDE_CHATBOX,
  HOST,
  MEILI_HOST,
  MEILI_KEY,
  MUTE_REDUX_LOGGER,
  NODE_ENV,
  RASA_URL,
  SEGMENT_API_KEY,
  SLY_ENV,
  TYPEFORM_FORMID,
  VERSION,
} from '../env';

const config: Record<string, any> = {
  all: {
    slyEnv: SLY_ENV,
    gaEnv: GA_ENV,
    isDev: NODE_ENV === 'development',
    isTest: false,
    isStaging: false,
    isProd: false,
    isCi: false,
    host: HOST,
    isBrowser: typeof window !== 'undefined',
    isServer: typeof window === 'undefined',
    apiUrl: API_URL,
    cmsUrl: CMS_URL,
    gMapsApiKey: GOOGLE_MAPS_API_KEY,
    gAnalyticsKey: 'UA-55078261-2',
    eventServerUrl: 'http://localhost:8888/events/new',
    domain: DOMAIN,
    assetsUrl: ASSETS_URL,
    facebookPixelId: '586147298262302',
    googleTagManagerId: 'GTM-NTC7HG2',
    rokoApiKey: 'OgRs7tffvTdiKOKqsDSwwLgyJF6wHYVxFAK+qQO4paU=',
    version: VERSION,
    facebookAppId: FB_CLIENT_ID,
    googleAppId: GOOGLE_CLIENT_ID,
    olarkSiteId: '9319-500-10-7635',
    causewayKey: CAUSEWAY_KEY,
    muteReduxLogger: true,
    disableExperiments: DISABLE_EXPERIMENTS,
    enableExperimentsDebugger: ENABLE_EXPERIMENT_DEBUGGER,
    tinyMCEApiKey: 'zalf4x4cr6354ko1klhtu90vmtbee1qw9r4j1qy1dpm3xabb',
    gadsClient: 'ca-pub-7265665320394778',
    gadSlots: {
      profile: '6273714139',
      resource: '3335478033',
      search: '2390417245',
    },
    greenhouseUrl: 'https://boards-api.greenhouse.io/v1/boards',
    hideChatbox: HIDE_CHATBOX,
    additionalAnalyticsPlatform: null,
    segmentApiKey: SEGMENT_API_KEY,
    typeformFormId: TYPEFORM_FORMID,
    logsAwsAccesKeyId: 'AKIATUMQA4JTJN52HVXO',
    logsAwsSecrectAccessKey: 'vVwm9NL1ngqznfU9KCGlzGexM8SLmwld1SwYIOr9',
    logsAwsLogGroup: 'slyreact-development-client-logger',
    meiliHost: MEILI_HOST,
    meiliKey: MEILI_KEY,
    builderApiKey: BUILDER_API_KEY,
    rasaUrl: RASA_URL,
  },

  test: {
    isTest: true,
    domain: 'localhost',
    host: 'http://localhost',
    apiUrl: 'http://localhost/v0',
    assetsUrl: 'http://localhost/assets',
  },

  development: {
    // hideChatbox: true,
    segmentApiKey: '7nWV7DWNQSlXey9da1GRBIZOxce3GRRS',
    muteReduxLogger: MUTE_REDUX_LOGGER,
  },

  ci: {
    isCi: true,
  },

  staging: {
    isStaging: true,
    eventServerUrl: 'https://event.myseniorly.com/events/new',
    facebookAppId: '299735431723954',
    googleAppId:
      '522248695659-ces03d2ptbgegiusk0c6v8j9abc7pdbf.apps.googleusercontent.com',
    segmentApiKey: 'geNh5KxqWlfgfdKCjAZrJGXqPiAhcO6d',
    logsAwsLogGroup: 'slyreact-staging-client-logger',
  },

  production: {
    isProd: true,
    eventServerUrl: 'https://event.seniorly.com/events/new',
    gAnalyticsKey: 'UA-55078261-2',
    olarkSiteId: '9319-500-10-7635',
    facebookPixelId: '586147298262302',
    googleTagManagerId: 'GTM-5888W7H',
    facebookAppId: '296657038622214',
    googleOptimizeId: 'OPT-TFLWN8C',
    googleAppId:
      '522248695659-kbpgg45i1pg4kt1ahsqm2trdr8cdms2k.apps.googleusercontent.com',
    logsAwsAccesKeyId: 'AKIATUMQA4JTGZWH57MT',
    logsAwsSecrectAccessKey: 'uifYNOt93Y7F49z7OWn3buPGuCDuZ6PqUCojSAGZ',
    logsAwsLogGroup: 'slyreact-prod-client-logger',
  },
};

const mergedConfig = merge(config.all, config[config.all.slyEnv]);

if (!mergedConfig.isServer && !mergedConfig.isTest) {
  console.info('Seniorly', mergedConfig.version);
}

export const domain = mergedConfig.domain;
export const apiUrl = mergedConfig.apiUrl;
export const cmsUrl = mergedConfig.cmsUrl;
export const gMapsApiKey = mergedConfig.gMapsApiKey;
export const gAnalyticsKey = mergedConfig.gAnalyticsKey;
export const eventServerUrl = mergedConfig.eventServerUrl;
export const facebookPixelId = mergedConfig.facebookPixelId;
export const googleTagManagerId = mergedConfig.googleTagManagerId;
export const rokoApiKey = mergedConfig.rokoApiKey;
export const causewayKey = mergedConfig.causewayKey;
export const facebookAppId = mergedConfig.facebookAppId;
export const googleAppId = mergedConfig.googleAppId;
export const olarkSiteId = mergedConfig.olarkSiteId;
export const tinyMCEApiKey = mergedConfig.tinyMCEApiKey;
export const gadSlots = mergedConfig.gadSlots;
export const gadsClient = mergedConfig.gadsClient;
export const greenhouseUrl = mergedConfig.greenhouseUrl;
export const hideChatbox = mergedConfig.hideChatbox;
export const additionalAnalyticsPlatform =
  mergedConfig.additionalAnalyticsPlatform;
export const segmentApiKey = mergedConfig.segmentApiKey;
export const typeformFormId = mergedConfig.typeformFormId;
export const logsAwsAccesKeyId = mergedConfig.logsAwsAccesKeyId;
export const logsAwsSecrectAccessKey = mergedConfig.logsAwsSecrectAccessKey;
export const logsAwsLogGroup = mergedConfig.logsAwsLogGroup;
export const slyEnv = mergedConfig.slyEnv;
export const isProd = mergedConfig.isProd;
export const isStaging = mergedConfig.isStaging;
export const isTest = mergedConfig.isTest;
export const isServer = mergedConfig.isServer;
export const isCi = mergedConfig.isCi;
export const isDev = mergedConfig.isDev;
export const assetsUrl = mergedConfig.assetsUrl;
export const isBrowser = mergedConfig.isBrowser;
export const version = mergedConfig.version;
export const host = mergedConfig.host;
export const meiliHost = mergedConfig.meiliHost;
export const meiliKey = mergedConfig.meiliKey;
export const rasaUrl = mergedConfig.rasaUrl;
export const builderApiKey = mergedConfig.builderApiKey;

export default mergedConfig;
